.customer {
  padding: 50px 30px;

  .closer-avatar {
    width: 20px;
  }

  .historie-entry {
    display: flex;
    gap: 10px;
  }

  .text-gray {
    color: #a3a3a3;
  }

  .timeline-icon {
    font-size: 28px;

    &.crossed {
      position: relative;
      display: inline-block;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #e74d3c;
        top: 50%;
        left: 0;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }


}


.cnt-edit-btn {
  background-color: #252645 ;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 200;
  font-size: smaller;


  margin-top: 5px;

  &:hover {
    filter: brightness(1.6);
  }
}