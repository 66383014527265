$primary-color: #6167e6;
$hover-color: #8f93cef8;
$font-family: "Arial", sans-serif;

.eventbooking {
  padding-bottom: 100px;


  .loading-calendar {
    margin-top: 50px;
  }

  .loading-calendar-text {
    display: block;
    margin: auto;
    width: fit-content;
    color: #5f5f5f;
    font-weight: 300;
  }

  .cal-info-text {
    display: block;
    width: fit-content;
    margin-left: 15px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #525252;
  }

  .loader {
    width: 175px;
    height: 80px;
    display: block;
    margin: auto;
    background-image: radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), radial-gradient(circle 50px at 50px 50px, #FFF 100%, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), linear-gradient(#FFF 50px, transparent 0);
    background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
    background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
    background-repeat: no-repeat;
    position: relative;
    box-sizing: border-box;
  }

  .loader::after {
    content: '';
    left: 0;
    right: 0;
    margin: auto;
    bottom: 20px;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-color: #6167e6 transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;

    th,
    td {
      padding: 12px;
    }

    th {
      background-color: $primary-color;
      color: white;
    }

    td {
      button {
        background-color: $primary-color;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }
}