header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
  position: relative;

  .mobile {
    visibility: hidden;
    display: none ;
  }
  
  h1 {
    font-size: 2rem;
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .logo {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 15px 0px 0px 0px;
    padding: 0;
  }

  li {
    margin: 0 1rem;

    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .profile-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 4rem;
    top: 5px;

    width: 30px;

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 0.8rem;
        font-weight: 500;

        &.name {
          font-weight: 500;
        }
      }
    }

    button {
      background-color: #6167e6;
      color: white;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 0.5rem;
      font-size: small;
      margin-top: 10px;
    }

    img {
      border-radius: 6px;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    h1 {
      font-size: 1.2rem;
      font-weight: 200;
    }

    img {
      width: 25px;
    }


  }

}

//mobile menu
@media screen and (max-width: 768px) {
  header {

    .mobile{
      visibility: visible;
      display: block;
    }

    .profile-area {
      visibility: hidden;
    }

    ul {
      display: none;
    }

    .profile-area {
      display: none;
    }

    .menu-icon {
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }

    .menu-icon img {
      width: 25px;
    }

    .menu-icon img:hover {
      opacity: 0.7;
    }

    .menu {
      display: unset;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #333;
      width: 100%;
      height: 100vh;
      z-index: 50;
      transform: translateX(100%);
      transition: all 0.5s ease-in-out;

      &.show {
        transform: translateX(0);
      }
    }


    .close-icon {
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }


    .menu ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 0;
    }

    .menu li {
      margin: 1rem 0;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .menu a {
      color: #fff;
      text-decoration: none;
    }


    h1 {
      margin-left: 15px;
    }

    .menu a:hover {
      text-decoration: underline;
    }
  }
}