.content-cancel {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 200px;
}


.message-box {
    background-color: #fff;
    display: inline-block;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    h1 {
        color: #333;
        font-size: larger;
    }
    p {
        color: #666;
    }
    .logo {
        margin-bottom: 20px;
    }
    .company-name {
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 20px;
    }
}
