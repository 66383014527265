.popup {

    &__header {
        padding: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;

        h2 {
            font-size: 1.25rem;
            font-weight: 600;
            margin: 0;
        }

        button {
            background: none;
            border: none;
            padding: 8px;
            cursor: pointer;
            border-radius: 50%;
            transition: background 0.2s;

            &:hover {
                background: #f5f5f5;
            }
        }
    }

    &__content {
        padding: 1.5rem;
    }

    &__section {
        margin-bottom: 1.5rem;

        &-title {
            font-size: 0.875rem;
            color: #666;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    &__coordinates {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        background: #f9f9f9;
        padding: 1rem;
        border-radius: 8px;

        &-item {
            label {
                display: block;
                font-size: 0.75rem;
                color: #666;
                margin-bottom: 0.25rem;
            }

            span {
                font-family: monospace;
                font-size: 0.875rem;
            }
        }
    }

    &__status {
        select {
            width: 100%;
            padding: 0.75rem;
            border: 1px solid #e2e2e2;
            border-radius: 6px;
            background: #fff;
            font-size: 0.875rem;
            cursor: pointer;

            &:focus {
                outline: none;
                border-color: #2563eb;
                box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
            }
        }
    }

    &__history {
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
        }

        &-user {
            min-width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 50%;
            background-color: var(--user-color);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: bold;
        }

        &-timestamp {
            font-size: 0.75rem;
            color: #666;
            display: flex;
            align-items: center;
            gap: 0.25rem;
        }

        &-list {
            border: 1px solid #e2e2e2;
            border-radius: 8px;
            max-height: 240px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: #c1c1c1;
                border-radius: 8px;
            }
        }

        &-item {
            display: flex;
            padding: 1rem;
            border-bottom: 1px solid #e2e2e2;

            &:last-child {
                border-bottom: none;
            }

            &-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.25rem;
            }

            &-user {
                font-weight: 500;
                font-size: 0.875rem;
            }

            &-time {
                font-size: 0.75rem;
                color: #666;
            }

            &-action {
                font-size: 0.75rem;
                color: #666;
            }
        }
    }
}