.wrap-login100 {
    width: 590px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);

    .login100-form {
        width: 100%;
    }

    .login100-form-btn{
        width: 100%;
        border-radius: 6px;
        font-size: 15px;
        height: 45px;
        background-color: #333333;
        color: #fff;

    }

    .login100-form-title {
        display: block;
        font-size: 30px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
    }

    .wrap-input100 {
        width: 100%;
        position: relative;
        border-bottom: 2px solid #adadad;
        margin-top: 37px;
        margin-bottom: 37px;
    }

    .input100{
        background: transparent;
        border: none;
        //hide autofill
        -webkit-box-shadow: 0 0 0 30px white inset;
        width: 100%;
    }


    .error-text{
        color: #dc2626;
        font-size: 12px;
        padding-top: 15px;
    }
}
