.notes {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 100px;

  overflow: hidden;
  overflow-y: auto;

  .note {
    img {
      width: 20px;
      object-fit: cover;
    }

    .sent-at {
      font-size: 12px;
      color: #797979;

      margin-left: 5px;
    }
  }
}
.send-note {
  display: flex;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #333;

  color: white;
  cursor: pointer;
  font-size: 14px;

  gap: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  .note {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
