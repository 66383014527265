.footer {
    background: #333;

    position: relative;
    z-index: 1;
    padding: 30px 10px;

    padding-bottom: 5px;

    color: white;

    .copyright {
        font-size: .8rem;
    }


    .list-group-item {
        background-color: transparent;
        display: block;
        position: relative;
        text-decoration: none;

        border: none;
    }

    .list-group-item:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    .list__item a {
        font-size: .9rem;
        text-decoration: none;
    }

    .list__item a {
        background: transparent;
        color: hsla(0, 0%, 100%, .772);
    }
}

@media screen and (max-width: 768px) {
    .footer {
        .logo {
            display: none;
        }
        .mb-4 {
            display: none;
        }
    }

}