.winkel-popup {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  display: none;

  .winkel-popup-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  .loader {
    width: 38px;
    height: 38px;
    border: 5px solid #00000049;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .dynamic-modal-content {
    margin-top: 30px;
    max-width: 750px;
  }
}

.byte-blitz-animated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.byte-blitz-bounce-in {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

.winkel-popup-container {
  .winkel-popup-message {
    overflow: hidden;
  }

  .fullscreen-bg-bottom {
    width: 100%;
    z-index: -1;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: static;
    opacity: 0.8;
    filter: brightness(0.5);
    bottom: 0;
    border-radius: 8px;

    position: absolute;
    -webkit-mask-image: linear-gradient(360deg, #000, transparent);
  }
}

.winkel-popup-input {
  border-radius: 10px;
  margin: 0px !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-block: 20px !important;

  p {
    color: #d3d3d3;
    font: 400 14px/30px "Poppins", Arial, sans-serif;
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
    justify-content: center !important;
  }

  input {
    margin: 0;
    border: 0;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
    background: #f2f2f2;
    line-height: 24px;
    padding: 5px !important;
    border-radius: 5px;
    background-color: #28283c !important;
    outline: 1px solid #ffffff !important;
    margin-block: 0px !important;
  }
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.04);
  }

  70% {
    opacity: 1;
    -webkit-transform: scale(0.98);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.04);
  }

  70% {
    opacity: 1;
    -webkit-transform: scale(0.98);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.winkel-popup.show {
  display: block;
}

.winkel-popup.show:before {
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
}

.winkel-popup .winkel-popup-wrapper {
  min-width: 680px;
  width: fit-content;
  max-width: 90%;
  display: block;
  position: relative;
  top: 50%;
  margin: 0 auto;
  text-align: right;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.winkel-popup .winkel-popup-container {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  display: block;
  text-align: left;
  -webkit-transform: scale(0.9, 0.9);
  -moz-transform: scale(0.9, 0.9);
  transform: scale(0.9, 0.9);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.winkel-popup.show .winkel-popup-container {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
}

.winkel-popup .winkel-popup-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.winkel-popup .winkel-popup-image {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: #fff;
  background-size: cover;
}

.winkel-popup .winkel-popup-content .winkel-popup-image {
  flex: 0 340px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.winkel-popup .winkel-popup-message {
  padding: 30px 30px 45px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.753);
  backdrop-filter: blur(4px);
}

.winkel-popup .winkel-popup-content .winkel-popup-message {
  flex: 1;
  border-radius: 10px;
}

.winkel-popup .winkel-popup-message h6 {
  margin-bottom: 25px;
  color: rgb(0, 0, 0);
  font: 600 18px/35px "Poppins", "Poppins", Arial, sans-serif;
  letter-spacing: -0.15px;
}

.winkel-popup .winkel-popup-message p {
  margin-bottom: 8px;
  color: #696969;
  font: 400 14px/30px "Poppins", Arial, sans-serif;
  letter-spacing: 0px;
}

.winkel-popup .winkel-popup-form {
  margin-top: 45px;
  margin-bottom: 0;
  position: relative;
}

.winkel-popup .winkel-popup-form input[type="email"] {
  margin: 0;
  border: 0;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  background: #f2f2f2;
  line-height: 24px;
}

@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }
}

.winkel-popup-button {
  background-color: #6167e6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  text-decoration: none;

  &.abort {
    background-color: #252645 !important;
  }

  &.disabled {
    background-color: #252645 !important;
    cursor: not-allowed;
  }
}

.winkel-popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.winkel-modal-close-button {
  display: block;
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 30px;
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.14);
  }
}

.link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-size: large;
  text-transform: uppercase;
  padding: 0 15px;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  text-decoration: underline;
}

.link:hover {
  color: #aaaaaa;
  text-decoration: none;
  transition: all 0.3s ease;
}

@media screen and (max-width: "768px") {
  .winkel-popup-wrapper {
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .close-button {
    display: none;
  }

  .winkel-popup-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;

    .winkel-popup-button {
      width: 80%;
      text-align: center;
      margin-block: 5px;
    }
  }

  .winkel-popup .winkel-popup-message {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 0 !important;
  }
}