@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");

@import "bootstrap/dist/css/bootstrap.min.css";



body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow-x: hidden;
}